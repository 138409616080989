import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SectionHeader from '../../components/SectionHeader'
import SectionTextTwoColumns from '../../components/SectionTextTwoColumns'
import Layout from '../../layout/Layout'

export default function Home() {
  const { headerBg } = useStaticQuery(
    graphql`
      query {
        headerBg: file(
          relativePath: { eq: "nasze-uslugi/transport-towarow-neutralnych.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
    <Layout
      metaTitle="Transport ładunków neutralnych"
      metaDescription="Zapraszamy do współpracy, jeśli chcesz zlecić drogowy transport ciężarowy towarów neutralnych. Mowa o ładunkach, które nie są regulowane prawnie, w związku z czym można je przewozić w dowolny sposób."
      slug="/uslugi/transport-ladunkow-neutralnych"
    >
      <SectionHeader
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            ładunków <br />
            neutralnych
          </>
        }
        titleBorder="white"
        lead="Zapraszamy do współpracy, jeśli chcesz zlecić drogowy transport ciężarowy towarów neutralnych. Mowa o ładunkach, które nie są regulowane prawnie, w związku z czym można je przewozić w dowolny sposób."
        content={
          <>
            Kluczowa dla konkurencyjności usług staje się zatem umiejętność
            sprawnej realizacji zleceń w&nbsp;jak najkorzystniejszej cenie –
            zdajemy sobie z&nbsp;tego sprawę, dlatego dążymy do zapewnienia jak
            najlepszej relacji jakości do oferowanej stawki. Powierzając nam
            przewóz towarów, masz pewność korzystnych warunków finansowych
            i&nbsp;profesjonalnej realizacji.
          </>
        }
        buttonType="bordered"
        scroll="#czytaj-wiecej"
        halfBg
        bgImage={getImage(headerBg)}
      />
      <SectionTextTwoColumns
        id="czytaj-wiecej"
        smallTitle="Transport ładunków neutralnych"
        title={
          <>
            Kompleksowa <br />
            obsługa <br />
            transportu
          </>
        }
        lead={
          <>
            Tym, co wyróżnia nas spośród konkurencji, jest kompleksowa obsługa
            i&nbsp;kontrola procesów transportowych. Realizujemy zlecenie od
            początku do końca – standardowo organizujemy przewóz ładunków,
            jednak na życzenie Klienta organizujemy również załadunek oraz
            rozładunek towaru.
          </>
        }
      >
        <p>
          Dokładnie monitorujemy pojazdy wiozące Twoje towary, dzięki czemu
          w&nbsp;każdej chwili możesz sprawdzić, gdzie one się znajdują
          i&nbsp;jaki jest przewidywany czas dotarcia do celu. Przekłada się to
          na terminowość i&nbsp;bezpieczeństwo dostaw, czyli jeden
          z&nbsp;najważniejszych aspektów, które decydują
          o&nbsp;konkurencyjności ciężarowego transportu drogowego towarów.
        </p>
        <p>
          Zapewniamy elastyczne i&nbsp;indywidualne podejście do każdego
          zlecenia, dzięki czemu możemy z&nbsp;powodzeniem je zrealizować
          niezależnie od specyfiki ładunków oraz Twoich indywidualnych potrzeb
          i&nbsp;wymagań. Zapraszamy do współpracy bez względu na to, czy
          zamierzasz zlecić jednorazowy, czy systematyczny transport towarów.
          W&nbsp;obu przypadkach spełnimy swoje zadanie w&nbsp;profesjonalny
          i&nbsp;efektywny sposób, dostarczając szybko, bezpiecznie i&nbsp;
          w&nbsp;korzystnej cenie Twoje ładunki. Posiadamy zasoby, dzięki którym
          możemy realizować zarówno transporty ciężarowe na kilka lub więcej
          samochodów, jak i&nbsp;przewozić pojedyncze palety z&nbsp;punktu
          A&nbsp;do punktu&nbsp;B. Wszystko zależy od Twoich potrzeb, wymagań
          i&nbsp;możliwości, którym zawsze się podporządkowujemy.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport ładunków neutralnych"
        title={<>Efektywny, bezpieczny i&nbsp;konkurencyjny cenowo transport</>}
        lead={
          <>
            Każde zlecenie rozpoczynamy od analizy oczekiwań Klienta. Omawiamy
            ilość i&nbsp;rodzaj towaru i&nbsp;pod ich kątem dobieramy typ oraz
            liczbę pojazdów wykorzystywanych do dostarczenia lub odebrania
            ładunków.
          </>
        }
      >
        <p>
          Następnie planujemy optymalną trasę i&nbsp;przechodzimy do realizacji
          ciężarowego transportu drogowego, aby jak najsprawniej dowieźć towary
          do celu. Możesz liczyć na dobór rozwiązań ograniczających koszty do
          minimum przy jednocześnie wysokiej jakości świadczonych usług. Takie
          połączenie jest tym, czego oczekują Klienci, których interesuje
          przewóz towarów.
        </p>
        <p>
          Dlaczego możesz nam zaufać? Ponieważ tworzymy doświadczony zespół
          spedytorów, którzy wiedzą, jak sprawnie i&nbsp;bezpiecznie realizować
          transport ciężarowy. Docieramy do dowolnych miejsc, o&nbsp;ile da się
          do nich dojechać samochodem. Pozwala na to rozbudowana sieć partnerów
          w&nbsp;całej Europie – dobór sprawdzonych oraz rzetelnych
          Podwykonawców przekłada się na wysoką jakość świadczonych usług.
          Bogate zasoby, którymi dysponujemy, umożliwiają skuteczne wykonanie
          nawet najbardziej skomplikowanych zleceń. W&nbsp;razie potrzeby
          realizujemy również drogowe transporty ponadgabarytowe.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport ładunków neutralnych"
        title={<>Pełne wsparcie w&nbsp;ciężarowym transporcie towarów</>}
        lead={
          <>
            Każdy pojazd, którym odbywa się przewóz towarów, podlega precyzyjnej
            i&nbsp;całodobowej kontroli. Dzięki temu w&nbsp;dowolnej chwili
            możesz dowiedzieć się, gdzie znajdują się Twoje ładunki i&nbsp;kiedy
            mniej więcej dotrą do celu.
          </>
        }
      >
        <p>
          Pełna dyspozycyjność i&nbsp;gwarancja szybkiego kontaktu
          z&nbsp;dedykowanym opiekunem to nieodłączne elementy standardów
          obsługi, które staramy się utrzymywać na możliwie wysokim poziomie.
          Robimy to również poprzez zapewnienie kompleksowej pomocy
          w&nbsp;niezbędnych formalnościach związanych z&nbsp;realizacją
          ciężarowego transportu drogowego.
        </p>
        <p>
          Proponujemy wsparcie przy zakupie ubezpieczeń CARGO, których wybór Ci
          podpowiadamy. Dokładnie analizujemy dostępne aktualnie na rynku polisy
          i&nbsp;sugerujemy zakupienie najlepszej w&nbsp;Twoim przypadku. Tym
          samym oszczędzasz czas i&nbsp;masz pewność trafnej, korzystnej
          decyzji. Zapewniamy również wsparcie w&nbsp;czynnościach celnych.
          Polecamy tę usługę każdemu, kto zleca drogowy transport ciężarowy do
          państw niezrzeszonych w&nbsp;Unii Europejskiej. Zamiast samemu
          zajmować się często skomplikowanymi i&nbsp;czasochłonnymi
          formalnościami, możesz je powierzyć doświadczonym i&nbsp;odpowiednio
          wykwalifikowanym specjalistom tworzącym nasz zespół.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport ładunków neutralnych"
        title={<>Uczciwa współpraca, korzystne warunki</>}
        lead={
          <>
            Jasne warunki, minimum formalności i&nbsp;konkurencyjne stawki to
            kolejne elementy oferty, poprzez które zachęcamy Klientów do
            współpracy.
          </>
        }
      >
        <p>
          Brak ukrytych opłat oraz elastyczne i&nbsp;indywidualne podejście do
          każdego zlecenia sprawiają, że jest ono realizowane zgodnie
          z&nbsp;Twoimi oczekiwaniami i&nbsp;w&nbsp;sposób odpowiadający
          pierwotnym ustaleniom. Zawsze dobieramy pojazdy i&nbsp;trasy przejazdu
          pod kątem bezpieczeństwa i&nbsp;szybkości, dopasowując się do Twoich
          potrzeb i&nbsp;wymagań.
        </p>
        <p>
          Korzystamy wyłącznie z&nbsp;nowoczesnego i&nbsp;wydajnego taboru,
          gwarantującego wydajność transportu towarów niezależnie od ich
          specyfiki i&nbsp;miejsca docelowego, do którego mają dotrzeć.
          Zapraszamy do współpracy firmy i&nbsp;osoby prywatne, które chcą
          powierzyć ciężarowy przewóz drogowy profesjonalistom!
        </p>
      </SectionTextTwoColumns>
    </Layout>
  )
}
